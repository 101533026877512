<script setup lang="ts">

import TheIcon from '@/Components/TheIcon.vue'
import { Image } from '@/Pages/the-country/interfaces/City'
import TheImageWebpThumb from '@/Components/the-image/TheImageWebpThumb.vue'
import { bottomBar } from '@/Composables/bottomBarState'
import { ref } from 'vue'

const props = withDefaults(
    defineProps<{
        name: string | null
        title: string | null
        image: Image | null
        url: string | null
        rawUrl: string | null
        isSticky?: boolean
    }>(),
    {
        isSticky: true
    }
)

const showOfferBtn = ref(true)

const toggleShowOfferBtn = (): void => {
    showOfferBtn.value = !showOfferBtn.value

    // Open the URL in a new tab
    if (props.rawUrl) {
        window.open(props.rawUrl, '_blank')
    }
}

</script>

<template>
    <!--a
        id="mapFloat"
        href="#location"
        :class="{
            'toBottom': !bottomBar,   
        }"
    >
        <img :src="route('images', 'map-float.jpg')" alt="" title="OPEN">
    </a!-->

    <div
        v-if="name && url"
        :class="{'sticky-elem': isSticky}"
        class="p-3 pt-5 p-sm-4 shadow-1 rounded mb-5"
    >
        <p class="title-3 mb-3 font-weight-bold">
            {{ title }}
        </p>
        <p class="h3 mb-3 text-center">
            {{ name }}!
        </p>
        <a
            v-if="image"
            class="position-relative mb-4 d-block"
            :href="url"
            :title="name"
            target="_blank"
            rel="nofollow"
        >
            <TheImageWebpThumb :image="image" />
        </a>
        <p class="mb-5 mb-lg-0 text-center">
            <span
                v-if="showOfferBtn"
                id="topHostelSidebar"
                :class="{'toBottom': !bottomBar}"
                class="sticky-sm-top-hostel"
            >
                <a
                    :href="url"
                    :title="name"
                    class="btn btn-lg btn-danger rounded px-5"
                    target="_blank"
                    rel="nofollow"
                >
                    Check out {{ name }} here
                </a>
                <button
                    id="topHostelHideButton"
                    type="button"
                    class="bg-light btn-clear hide-offer-btn"
                    @click="toggleShowOfferBtn"
                >
                    <TheIcon
                        stroke="#4a5268"
                        icon-id="close-icon-2"
                        width="15"
                        height="15"
                    />
                </button>
            </span>
        </p>
    </div>
</template>

<style scoped lang="scss">
.hide-offer-btn {
    transition: background-color 0.3s;
}
</style>